jQuery(function($){
    let image_array = [];
    $('.project .index_quick_menu_text a').each(function(){ image_array.push($(this).attr('href'))})
    $('body').on('click', '#shade', function(e){
        if(e.target.id == 'shade'){
            $('#shade').remove();
        }
    })
    $('.index_quick_menu_item').click(function(){
        let curr_item;
        $('#shade').remove();
        $('body').append(`
            <div id="shade">
                <div id="lightbox_container">
                    <img id="lightbox_image" src="${$(this).find('.index_quick_menu_text a').attr('href')}">
                    <div id="controls_container">
                        <div id="controls_left">
                            <div id="left_arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="black" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"></path></svg>
                            </div>
                            <div id="right_arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="black" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path></svg>
                            </div>
                            <div id="lightbox_counter">image ${$(this).index() + 1} of ${$('.index_quick_menu_item').length}</div>
                        </div>
                        <div id="controls_right">
                            <div id="close">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="black" d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `)
        curr_item = $(this).index()

        $('#close').click(function(){
            $('#shade').remove();
        })

        $('#left_arrow').click(function(){
            if(curr_item == 0){
                $('#lightbox_image').attr('src', `${image_array[image_array.length - 1]}`)
                $('#lightbox_counter').text(`image ${$('.index_quick_menu_item').length} of ${$('.index_quick_menu_item').length}`)
                curr_item = image_array.length - 1
            } else{
                $('#lightbox_image').attr('src', `${image_array[curr_item - 1]}`)
                $('#lightbox_counter').text(`image ${curr_item} of ${$('.index_quick_menu_item').length}`)
                curr_item --;
            }

        })

        $('#right_arrow').click(function(){
            if(curr_item == image_array.length - 1){
                $('#lightbox_image').attr('src', `${image_array[0]}`)
                $('#lightbox_counter').text(`image ${1} of ${$('.index_quick_menu_item').length}`)
                curr_item = 0
            } else{
                $('#lightbox_image').attr('src', `${image_array[curr_item + 1]}`)
                $('#lightbox_counter').text(`image ${curr_item + 2} of ${$('.index_quick_menu_item').length}`)
                curr_item ++;
            }
        })

        $('#lightbox_image').click(function(){
            if(curr_item == image_array.length - 1){
                $('#lightbox_image').attr('src', `${image_array[0]}`)
                $('#lightbox_counter').text(`image ${1} of ${$('.index_quick_menu_item').length}`)
                curr_item = 0
            } else{
                $('#lightbox_image').attr('src', `${image_array[curr_item + 1]}`)
                $('#lightbox_counter').text(`image ${curr_item + 2} of ${$('.index_quick_menu_item').length}`)
                curr_item ++;
            }
        })

    })
})